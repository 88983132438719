import React, { useState } from 'react';
import get from 'lodash/get';
import EntityForm from 'modules/entity/forms';
import { useNotistack, useRouter } from 'shared/hooks';
import { CreateBusinessFormElements } from './CreateBusinessFormElements';
import { useAsStaff } from '../../../pages/BusinessManagement/hooks/useAsStaff';
import { useTranslation } from 'react-i18next';

type CreateBusinessFormProps = {
	type: 'page' | 'popup';
	onSuccess?: () => void;
};

export function CreateBusinessForm({ type, onSuccess }: CreateBusinessFormProps) {
	const { history, location } = useRouter();
	const { loadAllBusiness, loadUserCompaniesRequest } = useAsStaff();
	const { showNotification } = useNotistack();
	const { t } = useTranslation();
	const [customRequiredFields, setCustomRequiredFields] = useState({
		custom_industry: false
	});
	return (
		<div>
			<EntityForm.Main
				entity="business"
				name="AllBusiness"
				url="/user/company/"
				method="post"
				primaryKey="id"
				sendAsFormData={false}
				prependData
				fields={[
					{
						name: 'name',
						required: true,
						value: '',
						type: 'string',
						onSubmitKey: ''
					},
					{
						name: 'industry',
						onSubmitValue: (value, values) => {
							if (values && 'custom_industry' in values) {
								// @ts-ignore
								return !values.custom_industry ? get(value, 'id') : null;
							}
							return null;
						},
						onSubmitKey: '',
						required: true,
						type: 'object',
						value: null
					},
					{
						name: 'sub_industry',
						onSubmitValue: (value, values) => {
							// @ts-ignore
							return !values.custom_sub_industry ? get(value, 'id') : null;
						},
						onSubmitKey: '',
						type: 'object',
						value: null
					},
					{
						name: 'custom_industry',
						value: null,
						required: customRequiredFields.custom_industry,
						onSubmitValue: (value) => {
							// @ts-ignore
							return customRequiredFields.custom_industry ? value : null;
						},
						onSubmitKey: ''
					},
					{
						name: 'custom_sub_industry',
						value: null,
						onSubmitKey: ''
					},
					{
						name: 'is_business',
						value: true,
						onSubmitKey: ''
					}
				]}
				onSuccess={(response, resetForm): any => {
					if (type === 'page') {
						history.push('/pending-page');

						//4012 - business can be auto accepted if available industry selected so user company can be requested, and activated business ready to use
						//if business is active, this request triggers initial requests and forces to open activated business instead of pending page
						loadUserCompaniesRequest();
					}
					if (type === 'popup') {
						loadAllBusiness();
						if (typeof onSuccess === 'function') {
							onSuccess();
						}
					}
					resetForm();
				}}
				onError={(error, resetForm): any => {
					// console.log(error);
					showNotification({
						message: error?.response?.data?.message,
						variant: 'error'
					});
					resetForm();
				}}
			>
				{({ isSubmitting, values }) => {
					return (
						<>
							{type === 'popup' ? (
								<h1 className="mb-4 text-xl font-semibold dark:text-white">
									{t('create_new_business')}
								</h1>
							) : null}
							<CreateBusinessFormElements
								setCustomRequiredFields={setCustomRequiredFields}
								isSubmitting={isSubmitting}
								values={values}
							/>
						</>
					);
				}}
			</EntityForm.Main>
		</div>
	);
}

CreateBusinessForm.defaultProps = {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSuccess: () => {}
};
