import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

export function useSearchQuery() {
	const [query, setQuery] = useState('');
	const [searchQuery] = useDebounce(query, 700);

	const values = useMemo(() => {
		return {
			query,
			setQuery,
			searchQuery
		};
	}, [searchQuery, query]);

	return values;
}
