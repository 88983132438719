import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiSearch } from 'react-icons/bi';

type Props = {
	query: string;
	handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	customPlaceholder?: string;
};

export const SearchInput = React.memo(function SearchInput({
	query,
	handleInput,
	disabled,
	customPlaceholder
}: Props) {
	const { t } = useTranslation();
	return (
		<>
			<input
				type="search"
				className={clsx(
					'w-full bg-kgrey-bg dark:bg-kdark-lighter' +
					' h-full rounded-10 pl-4 pr-10 text-sm outline-none ' +
					'absolute inset-0 border border-transparent placeholder-gray-300 dark:border-white/20 dark:text-white'
				)}
				placeholder={customPlaceholder ?? t('search_cases')}
				value={query}
				onChange={handleInput}
				disabled={disabled}
			/>
			<div className="icon absolute top-1/2 right-[10px] -translate-y-1/2 dark:text-white">
				<BiSearch />
			</div>
		</>
	);
});
